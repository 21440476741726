import Strings from "../../../constants/localization/localization";
import Spinner from "react/parkable-components/spinner/Spinner";
import TableRow from "react/parkable-components/tableRow/TableRow";
import React, {useState} from "react";
import {showAlert} from "../../../alerts";
import {setEmployeeSubscription} from "../../../redux/actions/subscriptions"
import {retryEmployeeSubscriptionPaymentAPI} from"../../../api/subscription";
import {connect} from "react-redux";
import {Token} from "../../../api/rest";

type AllProps = {
    isPfb: boolean,
    subscriptionId: number,
    api: string,
    token: Token,
}

const actions = {
    setEmployeeSubscription,
}

type SubscriptionPastDueComponentProps = AllProps & typeof actions;

function subscriptionPastDueComponent(props: SubscriptionPastDueComponentProps){

    const [retryingPayment, setRetryingPayment] = useState(false);

    const onRetryTransactionPress = async () => {
        setRetryingPayment(true);
        try {
            const {employeeSubscription} = await retryEmployeeSubscriptionPaymentAPI(props.api, props.token, props.subscriptionId);
            showAlert(Strings.payment_successful, Strings.thank_you);
            setRetryingPayment(false);
            props.setEmployeeSubscription(employeeSubscription);
        } catch (err) {
            console.log(err);
            showAlert((err as any)?.message ?? Strings.internal_error_if_persists, Strings.error);
            setRetryingPayment(false);
        }

    };

    return (<TableRow contentLeft={retryingPayment && <Spinner/>}  iconLeft={retryingPayment ? undefined : "dollarfilled"} buttonProps={{ red: true,
        iconRight: "cheveronright",
        onPressIn: onRetryTransactionPress,
        disabled: retryingPayment
    }} buttonText={Strings.retry}  >
        {Strings.payment_failed}
    </TableRow>)
}

export default connect(() => ({}), actions)(subscriptionPastDueComponent as React.FunctionComponent) as React.FunctionComponent<AllProps>;
