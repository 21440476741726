import React from "react";
import { View } from "react-native";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../constants/localization/localization";
import { Park } from "../../model/Park";

export default function LocationCard({ park }: { park: Pick<Park, "address"> }) {
    return (
        <View>
            <TableRow iconLeft={"locationpin"} label={Strings.location}>
                {park.address}
            </TableRow>
        </View>
    );
}
