import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Bay } from "../../model/Bay";
import Colours from "react/parkable-components/styles/Colours";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../constants/localization/localization";
import { Routes } from "../../navigation/root/root.paths";
import { useNavigation } from "../../navigation/constants";
import {BayDTO} from "react/model/BayDTO";

type Props = {
    bay?: BayDTO | Bay;
    onConfirmTandemParking?: (estimatedLeaveTime: string | null) => void;
};

export default function TandemBayAllocatedAlert(props: Props) {

    const navigation = useNavigation();

    const { bay, onConfirmTandemParking } = props;

    if (!bay || !bay.tandemPod) {
        return null;
    }

    const handlePress = () => {
        navigation.push(Routes.ConfirmStartTandemParkingView, {
            parkId: bay.park,
            onConfirmTandemParking
        });
    };

    return (
        <View style={styles.rowContainer}>
            <TableRow
                iconLeft="parkingfilled"
                chevron
                iconRightProps={{ color: Colours.NEUTRALS_BLACK }}
                backgroundColor={Colours.ORANGE_100}
                onPress={handlePress}
            >
                <Text style={{color: Colours.NEUTRALS_BLACK}}>{Strings.tandem_parking.allocated}</Text>
            </TableRow>
        </View>
    );
};

const styles = StyleSheet.create({
    rowContainer: {
        marginBottom: 9,
    },
});
