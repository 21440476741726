import React from "react";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../../constants/localization/localization";

export type LocationRowProps = {
    displayName?: string | null;
    isLoading?: boolean
};
export const CarParkNameRow = ({ displayName, isLoading }: LocationRowProps) => {
    return (
        <TableRow
            iconLeft={"parkingfilled"}
            label={Strings.car_park}
        >
            {isLoading ? Strings.loading : displayName}
        </TableRow>
    );
};
