import React, { useRef, useState } from "react";
import {Image, View} from 'react-native';
import { contentStyles, styles, dialogStyles } from "./styles";
import {Button, Spinner, Text, DialogRef} from 'react/parkable-components';
import Dialog from "react/components/dialog/Dialog";
import Strings from '../../constants/localization/localization';
import {redeem, useMyRewards} from "../../api/rewards/reward.api";
import { RewardsActivityView } from "./rewardsActivityView";
import moment from "moment";
import {connect} from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import {showAlert} from "../../alerts";
import { Routes } from "../../navigation/root/root.paths";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import {PADDING} from "../../root/root.constants";
import Colours from "../../parkable-components/styles/Colours";

const MaskGroup = require('../../resources/Mask-Group-15478.png');

const getReduxProps = (state: IRootReducer) => {
    return { currentUser: state.user.user, };
};

export class MyRewardsParams {
    organisationId: number;
    territoryId?: number;
};

const _MyRewardsView = (props: ReturnType<typeof getReduxProps> & NavigationProps<Routes.MyRewardsView>) => {

    const navigation = useNavigation();

    const { rewards, bonusBays, mutate } = useMyRewards();

    const dialogRef = useRef<DialogRef|null>(null);
    const [loading, setLoading] = useState(false);

    if (!rewards) {
        return <View><Spinner large /></View>
    }

    const currentBalance = rewards.length ? Math.floor(rewards.map(b => b.amount - b.redeemedAmount).reduce((sum,amount) => sum + amount) / 100) : 0;

    const confirmedRewards = rewards?.filter(r => moment().diff(r.availableAt, 'second') >= 1);
    const pendingRewards = rewards?.filter(r => moment().diff(r.availableAt, 'second') < 0);

    const confirmedBalanceInCents = confirmedRewards.length ?
            confirmedRewards.map(b => b.amount - b.redeemedAmount).reduce((sum,amount) => sum + amount) : 0;

    const confirmedBalance = Math.floor(confirmedBalanceInCents /100);

    const onRedeemReward = async () => {
        setLoading(true);
        try {
            await redeem({
                amountToRedeem: confirmedBalanceInCents,
                territoryId: confirmedRewards[0].territory
            });
            await mutate();
            dialogRef.current?.show();
        } catch (e) {
            console.log("error on redeem", e);
            showAlert((e as any)?.response?.data?.message ?? Strings.unable_to_redeem_reward, Strings.whoops)
        } finally {
            setLoading(false);
        }
    };

    const onCloseResponse = () =>{
        dialogRef.current?.hide();
    };

    return (<ParkableBaseView loading={loading} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>

        <View style={contentStyles.container}>
            <View style={{paddingHorizontal: PADDING}}>
            <Text bold h1>{Strings.rewards.my_rewards}</Text>

            <View style={styles.describeBox}>
                <Text p small style={styles.describeText}>
                    {`${Strings.rewards.rewards_info_text} `}
                    <Text onPress={() => navigation.push(Routes.HowItWorks_Rewards, {
                        organisationId: props.route.params?.organisationId,
                        territoryId: rewards?.length > 0 ? rewards[0].territory : props.route.params?.territoryId
                    })}
                          small style={{color: Colours.BLUE_300}}>
                        {Strings.rewards.rewards_of_your_choice}
                    </Text>
                </Text>
            </View>

            <Text bold center>
                {Strings.rewards.your_balance_is}
            </Text>

            <Text h1 center style={styles.currentBalanceTxt} allowFontScaling={false}>
                {Strings.rewards.balance_reward(currentBalance)}
            </Text>
            </View>
            <View style={{flexDirection: "row"}}>
                <Image style={styles.handsImage} source={MaskGroup} />
            </View>

            <View style={{paddingHorizontal: PADDING}}>
            <Button iconRight={"arrowlightright"} style={styles.redeemBtn} center
                    disabled={confirmedBalance === 0 || loading}
                    onPress={onRedeemReward}>{Strings.rewards.redeem_reward}</Button>

            <Text bold h2 style={styles.activityLabel}>{Strings.rewards.activity}</Text>

            <View style={styles.rewardsTabs}>
                <View style={{flex: 1}}>
                    {!pendingRewards?.length && !confirmedRewards?.length &&
                    <Text small center style={styles.noRewards}>{Strings.rewards.no_rewards_found}</Text>}
                    {confirmedRewards?.map(reward =>{
                        const bonusBay = bonusBays!.filter(bb => bb.id === reward.bonusBay)[0];
                        return <RewardsActivityView
                            reward={reward}
                            bonusBay={bonusBay}
                        />
                    })}
                    {pendingRewards?.map(reward =>{
                        const bonusBay = bonusBays!.filter(bb => bb.id === reward.bonusBay)[0];
                        return <RewardsActivityView
                            isPending
                            reward={reward}
                            bonusBay={bonusBay}
                        />
                    })}
                </View>
                </View>
            </View>
        </View>

        <Dialog style={dialogStyles.container} ref={dialogRef} iconFABProps={{style: dialogStyles.fab}}
                onModalHide={onCloseResponse}
                icon={'awardTrophyStar'} iconProps={{white: true, style:{paddingTop:6, paddingLeft:2}}}
                label={Strings.rewards.congratulations} labelProps={{h3:true, style: dialogStyles.label}}>
            <Text style={dialogStyles.text}>{Strings.rewards.rewards_sent_to_email}</Text>
            <Button center style={dialogStyles.button}
                    onPress={onCloseResponse}>{Strings.rewards.back_to_my_rewards}</Button>
        </Dialog>
    </ParkableBaseView>);
};

export const MyRewardsView = connect(getReduxProps)(
    _MyRewardsView
);

export const MyRewardsViewRoute = createRoute({
    path: Routes.MyRewardsView,
    params: {type: MyRewardsParams}
})
