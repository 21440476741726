import React, { useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import Strings from "react/constants/localization/localization";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import { Bay, SharingPoolBayWithTandemPod } from "../../model/Bay";
import { sortBaysBySignage } from "react/constants/sortBaysBySignage";
import { usePark } from "../../api/park";
import { ParkingType } from "../../model/Types";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "./ParkableBaseView";
import { spacing } from "react/constants/theme/spacing";
import { Button } from "react/parkable-components";
import { groupBy } from "lodash";
import { useUserRoles } from "react/api/user/user.api";
import { BayOption } from "./bay/BayOption";
import { BayDTO, SharingPoolBayDTO } from "react/model/BayDTO";
import { userIsOrgMember } from "react/constants/getUserMember";

export default function SelectBayView(props: NavigationProps<Routes.SelectBayView>) {
    const { navigation } = props;

    const { baysAvailable: allBaysAvailable, onBaySelected, parkingType, bay, parkId } = props.route.params;

    const { park } = usePark(parkId);
    const { userRoles } = useUserRoles();
    const [showAllPressed, setShowAllPressed] = useState<boolean>(false);
    const [newBaySelected, setNewBaySelected] = useState<Bay | undefined>(undefined);

    // Refs to components on the screen.
    const baysAvailableRef = useRef<View>();
    const [availableBaysLocation, setAvailableBaysLocation] = useState<number>(300);
    const bayOptionRef = useRef<View>();
    const [bayOptionHeight, setBayOptionHeight] = useState<number>(57);
    // Some margin buffer & the height of the "show all" button.
    const screenSpaceLeft = Dimensions.get("window").height - availableBaysLocation - 108;
    // Tandem bays aren't included in this - all tandem bays are always displayed.
    const numberBaysShown = Math.round(screenSpaceLeft / (bayOptionHeight + 1)) - 1;

    const tandemBaysAvailable: SharingPoolBayWithTandemPod[] = [];
    const singleBaysAvailable: SharingPoolBayDTO[] = [];
    // allBaysAvailable includes all bays available to the user including sharing pool bays
    allBaysAvailable?.forEach((bay) => {
        bay.tandemPod ? tandemBaysAvailable.push(bay as SharingPoolBayWithTandemPod) : singleBaysAvailable.push(bay);
    });

    const sortedSingleBays: SharingPoolBayDTO[] = sortBaysBySignage(singleBaysAvailable);
    const sortedTandemBays: SharingPoolBayWithTandemPod[] = sortBaysBySignage(tandemBaysAvailable);

    const tandemBaysGroupedByPod = groupBy(sortedTandemBays, "tandemPod");
    const tandemPods = Object.values(tandemBaysGroupedByPod).map((bays) => ({
        id: bays[0].tandemPod,
        bays: bays,
        signage: bays
            .map((b) => b.signage)
            .filter((item) => item !== null)
            .join(", "),
    }));
    const otherTandemPods = tandemPods.filter((tandemPod) => tandemPod.id !== bay?.tandemPod);

    const otherSingleBays = sortedSingleBays.filter((b) => b.id !== bay?.id);
    const visibleBays = showAllPressed ? otherSingleBays : otherSingleBays.slice(0, numberBaysShown);

    const onSelectedBayConfirmSave = (bay: Bay) => {
        navigation.pop();
        onBaySelected?.(bay);
    };

    const isSubscription = parkingType === ParkingType.LONG_TERM;
    const isUserPrivateInOrg = userIsOrgMember(userRoles, park?.organisation);
    const isCurrentBaySharingPool = allBaysAvailable.find((b) => b.id === bay?.id)?.isSharingPool && isUserPrivateInOrg;

    const handleNewBaySelected = (newBay?: Bay) => {
        setNewBaySelected(newBay?.id === bay?.id ? undefined : newBay);
    };

    return (
        <ParkableBaseView scrollable={false}>
            <Text h1 style={{ marginBottom: spacing(1) }}>
                {Strings.change_bay}
            </Text>
            <Text h4 style={{ marginBottom: 33, color: Colours.NEUTRALS_500 }}>
                {Strings.choose_new_parking_bay}
            </Text>
            <ScrollView style={{ flex: 1 }}>
                {bay && (
                    <>
                        <View>
                            <Text h3 bold style={{ marginBottom: 0, color: Colours.NEUTRALS_900 }}>
                                {Strings.change_from}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            {bay.tandemPod && (
                                <Text h5 style={styles.tandemText}>
                                    {Strings.tandem_parking.tandem}
                                </Text>
                            )}
                            <BayOption
                                bays={bay.tandemPod ? tandemPods.find((t) => t.id === bay.tandemPod)?.bays : [bay]}
                                signage={
                                    (bay.tandemPod
                                        ? tandemPods.find((t) => t.id === bay.tandemPod)?.signage
                                        : bay.signage) || ""
                                }
                                isSelected={!newBaySelected}
                                isSubscription={isSubscription}
                                isSharingPool={isCurrentBaySharingPool}
                                onPress={handleNewBaySelected}
                            />
                        </View>
                    </>
                )}
                <View
                    // @ts-ignore
                    ref={baysAvailableRef}
                    onLayout={() =>
                        baysAvailableRef.current?.measureInWindow((fx, fy) => {
                            setAvailableBaysLocation(fy);
                        })
                    }
                >
                    <Text h3 bold style={{ marginTop: spacing(4), marginBottom: 0, color: Colours.NEUTRALS_900 }}>
                        {Strings.available_bays}
                    </Text>

                    {visibleBays.map((bay: SharingPoolBayDTO) => (
                        <View
                            style={styles.row}
                            key={bay.id}
                            // @ts-ignore
                            ref={bayOptionRef}
                            onLayout={() =>
                                bayOptionRef.current?.measureInWindow((fx, fy, width, height) => {
                                    setBayOptionHeight(height);
                                })
                            }
                        >
                            <BayOption
                                bays={[bay]}
                                // These will always be signage bays.
                                signage={bay.signage!}
                                isSelected={newBaySelected?.id === bay.id}
                                isSubscription={isSubscription}
                                isSharingPool={isUserPrivateInOrg && bay.isSharingPool}
                                onPress={handleNewBaySelected}
                            />
                        </View>
                    ))}

                    {otherTandemPods.map((tandemPod) => (
                        <View style={styles.row} key={tandemPod.id}>
                            <Text h5 style={styles.tandemText}>
                                {Strings.tandem_parking.tandem}
                            </Text>
                            <BayOption
                                bays={tandemPod.bays}
                                signage={tandemPod.signage}
                                isSelected={tandemPod.bays.map((b) => b.id).includes(newBaySelected?.id!)}
                                isSubscription={isSubscription}
                                isSharingPool={isUserPrivateInOrg && tandemPod.bays.some((b) => b.isSharingPool)}
                                onPress={handleNewBaySelected}
                            />
                        </View>
                    ))}

                    {!showAllPressed && sortedSingleBays.length > numberBaysShown && (
                        <Text small style={styles.showAll} onPress={() => setShowAllPressed(true)}>
                            {Strings.show_all}
                        </Text>
                    )}
                </View>
            </ScrollView>
            {newBaySelected && (
                <View style={{ paddingTop: spacing(2) }}>
                    <Button
                        border
                        center
                        textProps={{ h5: true }}
                        style={styles.button}
                        disabled={!newBaySelected}
                        onPress={() => onSelectedBayConfirmSave(newBaySelected)}
                    >
                        {Strings.save}
                    </Button>
                </View>
            )}
        </ParkableBaseView>
    );
}

class SelectBayParams {
    baysAvailable: SharingPoolBayDTO[];
    onBaySelected: (bay: Bay) => void;
    bay?: Bay | BayDTO | null;
    parkId: number;
    parkingType: ParkingType;
}

export const SelectBayRoute = createRoute({
    path: Routes.SelectBayView,
    params: { type: SelectBayParams },
});

const styles = StyleSheet.create({
    row: {
        paddingTop: 15,
        paddingBottom: 12,
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_LIGHT,
    },
    tandemText: {
        marginBottom: spacing(0.33),
        color: Colours.NEUTRALS_500,
        fontSize: 13,
    },
    showAll: {
        color: Colours.BLUE_300,
        paddingTop: spacing(4),
    },
    button: {
        width: "100%",
        height: spacing(6),
        borderColor: Colours.GREY_BORDER,
        borderRadius: 5,
        borderWidth: 1,
        paddingVertical: 10,
        marginVertical: 5,
        alignItems: "center",
        justifyContent: "center",
    },
});
