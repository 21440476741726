import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import React from "react";
import Strings from "react/constants/localization/localization";
import { ParkDTO } from "react/model/ParkDTO";
import { useDirectionsPressHandler } from "../common/maps/Map.utils";

type Props = {
    park: Omit<ParkDTO, "territory"> | null;
};

const DriveTimeTableRow = ({park}: Props) => {
    const handleDirectionsPress = useDirectionsPressHandler(park);

    return (
        <TableRow
            iconLeft={"locationpin"}
            textProps={{ small: true, numberOfLines: 2 }}
            buttonProps={{
                plain: true,
                textProps: {
                    style: {
                        width: 80,
                        textAlign: "right",
                        color: Colours.BLUE_300,
                        fontSize: 15,
                        fontFamily: "GTEestiDisplay-Regular",
                        margin: 0,
                        padding: 0,
                        alignItems: "flex-end",
                    },
                },
            }}
            buttonText={Strings.directions}
            onButtonPress={handleDirectionsPress}
        >
            {park?.address}
        </TableRow>
    );
}

export default DriveTimeTableRow;
