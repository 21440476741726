import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Keyboard,
    StyleSheet,
    TouchableWithoutFeedback,
    View
} from "react-native";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import HelperBlock from "react/parkable-components/helperBlock/HelperBlock";
import Icons from "react/parkable-components/icon/Icons";
import Input from "react/parkable-components/input/Input";
import InputWrapper from "react/parkable-components/inputWrapper/InputWrapper";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import Strings from '../../../constants/localization/localization';
import {
    cancelEmailChange,
    deleteInstallationId,
    removeUserToken,
    sendEmailVerificationLink,
    updateUser,
    userLoggedOut
} from "../../../redux/actions/user";
import * as Yup from "yup";
import { Routes } from "react/navigation/root/root.paths";
import useSafeArea from "../../../hooks/useSafeArea";
import {showAlert} from "../../../alerts";
import {deleteUserAccountAPI} from "../../../api/user";
import {FirebaseService, isFirebaseError} from "../../../services/firebase.service";
import {createRoute, NavigationProps} from "../../../navigation/constants";
import {loadParkableUser, setUser} from "../../../redux/actions/user";
import ParkableBaseView from "../../common/ParkableBaseView";
import useAuthToken from "../../../constants/useAuthToken";
import { useAppDispatch, useSelector } from "../../../redux/redux";
import { useTerritories } from "../../../api/territory/territory.api";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { UserDTO } from "react/api/user/dto/user.dto";
import validatePhoneNumber, {
    getAreaCodeFromPhoneNumber,
    getCountryFromPhoneNumber
} from "react/constants/validatePhoneNumber";
import validateEmail from "react/constants/validateEmail";
import CountryPicker, {Country, CountryCode} from "react-native-country-picker-modal";
import { useCountries } from "react/constants/Util";

type Props = NavigationProps<Routes.MyDetailsView>;

const userSchema = Yup.object().shape({
    email: Yup.string().email(Strings.invalid_email).required(Strings.required),
    firstName: Yup.string().required(Strings.required),
    lastName: Yup.string().required(Strings.required),
    companyDetails: Yup.string().nullable(true),
});

export default function MyDetailsView(props: Props) {

    const dispatch = useAppDispatch();
    const authToken = useAuthToken();

    const { api } = useSelector(state => state.data);
    const { user, installationId } = useSelector(state => state.user);
    const { authChangesAllowed } = user || {};
    const { territories } = useTerritories();

    const formRef = useRef<FormikProps<UserDTO> | null>(null);
    const { top } = useSafeArea();
    const { navigation, route } = props;
    const [isLoading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const [phone, setPhone] = useState<string|null>(null);
    const passwordUpdated = useRef<boolean>(false);
    const pwdUpdatedDialogRef = useRef<DialogRef|null>(null);
    const preferredCountries: CountryCode[] = ["NZ", "AU", "US", "GB"]
    const [isCountrySelectVisible, setIsCountrySelectVisible] = useState(false)
    const countries = useCountries();
    const countryCode = useMemo((): CountryCode | null => {
        if(user?.countryCode){
            return (user.countryCode === "UK" ? "GB" : user.countryCode) as CountryCode;
        }
        if(user?.phone){
            return getCountryFromPhoneNumber(user.phone) as CountryCode | null;
        }
        return null;
    }, [user]);
    const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(countryCode)
    const country = selectedCountry ? countries?.find(value => value.cca2 === selectedCountry) : null;
    const countryName = country?.name as string;

    const initialAreaCode = useMemo((): string => {
        const _country = countries?.find(value => value.cca2 === countryCode);
        if(_country){
            return `+${_country.callingCode[0]}`;
        }
        if(user?.phone){
            return getAreaCodeFromPhoneNumber(user?.phone)??"";
        }
        return ""
    }, [countryCode, user]);

    const areaCode = useMemo((): string => {
        if(country){
            return `+${country.callingCode[0]}`;
        }
        if(user?.phone){
            return getAreaCodeFromPhoneNumber(user?.phone)??"";
        }
        return ""
    }, [country, user]);

    const deleteRef = useRef<DialogRef|null>(null);
    const passwordDialogRef = useRef<DialogRef|null>(null);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);
    const phoneWithoutAreaCode = user?.phone?.replace(areaCode ?? "", "") ?? "";
    const loading = isLoading || !territories || !user;

    // Change email
    async function onResendEmailVerificationPress() {
        if(!user?.id) {
            return
        }
        try {
            setLoading(true);
            await dispatch(sendEmailVerificationLink(user?.id, "https://links.parkable.com"));
            alert("Sent!");
        } finally {
            setLoading(false)
        }
    }

    async function onCancelEmailChangePress() {
        if(!user?.id) {
            return;
        }
        try {
            setLoading(true);
            await dispatch(cancelEmailChange(user.id));
            await check();
        } finally {
            setLoading(false)
        }
    }

    const pendingEmail = user?.pendingEmail;
    const pendingEmailRef = useRef(pendingEmail);

    useEffect(() => {
        if(phone === null && user){
            setPhone(phoneWithoutAreaCode);
        }
    }, [user, phone, phoneWithoutAreaCode])

    useEffect(() => {
        const prevPendingEmail = pendingEmailRef.current;
        pendingEmailRef.current = pendingEmail;
        if(pendingEmail) {
            //check every two seconds
            const intervalId = setInterval(check, 2000);
            return () => clearInterval(intervalId);
        }
        if (prevPendingEmail && prevPendingEmail === user?.email) {
            // If the user's email is the same as the previous pending email, then we know the new email has been verified.
            // We have to kick the user out because Firebase has already invalidated their auth token and refresh token.
            dispatch(userLoggedOut());
            FirebaseService.logout().catch(console.log);
        }
    }, [pendingEmail]);

    async function check() {
        const user = await loadParkableUser(api, authToken);
        dispatch(setUser(user));
    }

    useEffect(() => {
        if(!passwordUpdated.current && !!route.params?.passwordUpdated) {
            pwdUpdatedDialogRef.current?.show();
        }
    }, [passwordUpdated, route.params?.passwordUpdated, pwdUpdatedDialogRef]);

    const goChangePassword = useCallback(() => {
        navigation.replace(Routes.ChangePasswordView);
    }, [navigation]);

    const onDeleteUserAccountPress = async () => {
        setLoading(true);
        try {
            deleteRef.current?.hide();
            await deleteUserAccountAPI(user!.id);
            dispatch(removeUserToken());
            if(!!installationId) {
                await dispatch(deleteInstallationId(installationId));
            }
            dispatch(setUser(null));
            dispatch(userLoggedOut());
            await FirebaseService.logout();
            setLoading(false);
        } catch (err) {
            showAlert((error as any)?.message, Strings.error);
        } finally {
            setLoading(false);
        }
    };

    function onPasswordFormClose() {
        setPassword("");
        setPasswordError("");
    }

    const initialValues = {
        ...(user ?? {})
    } as UserDTO;

    const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
        const value = formRef.current?.values!;
        const _phone = areaCode + phone;
        if (validatePhoneNumber(_phone)) {
            return;
        }
        if (validateEmail(value.email)) {
            setError(Strings.invalid_email_longer);
            return;
        }
        if (value.email !== user?.email) {
            if(password) {
                try {
                    setIsPasswordSubmitting(true);
                    await FirebaseService.reauthenticate(password);
                    passwordDialogRef.current?.hide();
                } catch (e: unknown) {
                    console.error(e);
                    setPasswordError(isFirebaseError(e) && e.code === "auth/wrong-password"
                        ? Strings.incorrect_password
                        : Strings.unknown_error_occurred);
                    return;
                } finally {
                    setIsPasswordSubmitting(false);
                }
            } else {
                passwordDialogRef.current?.show();
                return;
            }
        }
        setError(null);
        setLoading(true);
        try {
            await dispatch(
                updateUser(
                    value.id,
                    value.email,
                    value.firstName,
                    value.lastName,
                    _phone,
                    selectedCountry === "GB" ? "UK" : selectedCountry,
                    null,
                    null,
                    value.companyDetails ?? "",
                    () => setEditing(false),
                    (error) => setError(error.message ?? Strings.unknown_error_occurred)
                )
            );
        } catch (error) {
            console.log({ error });
            setError((error as any).message ?? Strings.unknown_error_occurred);
        } finally {
            resetForm({ values })
            navigation.replace(Routes.MyDetailsView, {})
            setLoading(false);
        }
    };

    const onButtonPress = useCallback(() => {
        if(editing) {
            formRef.current?.submitForm();
        } else {
            setEditing(true);
        }
    }, [editing, formRef]);

    const onSelectCountry = (country: Country) =>{
        const _countryCode = country.cca2;
        if(_countryCode !== selectedCountry){
            setSelectedCountry(_countryCode)
            setPhone("");
        }
    }

    const phoneError = useMemo(() => {
        if(phone === null || !editing){
            return null;
        }

        if(!phone){
            return Strings.required;
        }else{
            if (validatePhoneNumber(areaCode + phone)) {
                return Strings.invalid_phone;
            }
        }
        return null;
    }, [phone, editing]);

    return (
        <ParkableBaseView loading={loading}>
            <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
                <View style={styles.base}>
                        <View style={styles.header}>
                            <Text h1 bold>
                                {Strings.my_details}
                            </Text>
                        </View>

                            <Formik<UserDTO>
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={userSchema}
                                innerRef={formRef}
                                enableReinitialize={true}
                                >
                                    {({ handleChange,
                                          handleBlur,
                                          resetForm, values,
                                          errors,
                                          dirty,
                                          isValid,
                                    }) => {
                                        const _dirty = dirty || (phone !== phoneWithoutAreaCode);
                                        const _isValid = isValid && !phoneError;
                                        setFormValid(_dirty && _isValid);
                                        return (
                                            <><View>
                                                <Input
                                                    label={Strings.email_address.toUpperCase()}
                                                    containerStyle={styles.input}
                                                    disabled={!editing || !authChangesAllowed}
                                                    style={(!editing || !authChangesAllowed) && styles.disabled}
                                                    onChangeText={handleChange("email")}
                                                    onBlur={handleBlur("email")}
                                                    keyboardType="email-address"
                                                    value={values.email}
                                                    error={errors.email} />
                                                {!!user?.pendingEmail &&
                                                    <Text style={styles.pendingEmailChange}>
                                                        {Strings.email_change_instructions(user.pendingEmail)}{" "}
                                                        <Text
                                                            onPress={onResendEmailVerificationPress}
                                                            style={[
                                                                styles.pendingEmailChangeButton,
                                                                styles.pendingEmailChange,
                                                            ]}>
                                                            {Strings.resend}
                                                        </Text>{" "}
                                                        or{" "}
                                                        <Text
                                                            onPress={onCancelEmailChangePress}
                                                            style={[
                                                                styles.pendingEmailChangeButton,
                                                                styles.pendingEmailChange,
                                                            ]}>
                                                            {Strings.cancel}
                                                        </Text>
                                                    </Text>}

                                                <Input
                                                    label={Strings.first_name.toUpperCase()}
                                                    containerStyle={styles.input}
                                                    disabled={!editing}
                                                    style={!editing && styles.disabled}
                                                    onChangeText={handleChange("firstName")}
                                                    onBlur={handleBlur("firstName")}
                                                    value={values.firstName ?? ""}
                                                    error={errors.firstName} />
                                                <Input
                                                    label={Strings.last_name.toUpperCase()}
                                                    containerStyle={styles.input}
                                                    disabled={!editing}
                                                    style={!editing && styles.disabled}
                                                    onChangeText={handleChange("lastName")}
                                                    onBlur={handleBlur("lastName")}
                                                    value={values.lastName ?? ""}
                                                    error={errors.lastName} />
                                                <View style={{ position: "relative", marginTop: 9 }}>
                                                    <Text bold strapline style={{ color: Colours.GREY_60 }}>
                                                        {Strings.company_details.toUpperCase()}
                                                    </Text>
                                                    <Input
                                                        containerStyle={{ marginBottom: 16 }}
                                                        disabled={!editing}
                                                        style={!editing && styles.disabled}
                                                        onChangeText={handleChange("companyDetails")}
                                                        onBlur={handleBlur("companyDetails")}
                                                        value={values.companyDetails ?? ""}
                                                        error={errors.companyDetails}
                                                        autoCapitalize={"sentences"}
                                                        numberOfLines={5}
                                                        textAlignVertical={"top"}
                                                        multiline />
                                                    <Text small style={{ fontSize: 12, lineHeight: 12, color: Colours.GREY_50, position: "absolute", bottom: 0 }}>
                                                        {Strings.campany_detail_caption}
                                                    </Text>
                                                </View>

                                                <InputWrapper label="Country"
                                                              iconRight={"cheverondown"}
                                                              style={!editing ? {backgroundColor: Colours.GREY_10} : {}}
                                                              disabled={!editing}
                                                              containerStyle={{marginTop:18}}
                                                              iconRightProps={{ small: true }}
                                                              onPress={() => editing ? setIsCountrySelectVisible(true) : undefined}
                                                              focused={false}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        {<Text small>{selectedCountry && countryName ? countryName : (editing ? Strings.select_one : "")}</Text>}
                                                        <CountryPicker visible={isCountrySelectVisible}
                                                           onClose={() => setIsCountrySelectVisible(false)}
                                                           containerButtonStyle={{ marginTop: -9 }}
                                                           onSelect={country => onSelectCountry(country)}
                                                           preferredCountries={preferredCountries}
                                                            // @ts-ignore
                                                           countryCode={selectedCountry}
                                                           placeholder=""
                                                           withCloseButton
                                                           withEmoji={false}
                                                           withFlagButton={false}
                                                           withFilter />
                                                    </View>
                                                </InputWrapper>
                                                <Input
                                                    label={Strings.phone_number.toUpperCase()}
                                                    containerStyle={styles.input}
                                                    disabled={!editing}
                                                    style={!editing && styles.disabled}
                                                    iconLeft={areaCode != null ? <Text grey small>{areaCode}</Text> : undefined}
                                                    phone
                                                    onChangeText={async (value) => {
                                                        setPhone(value)
                                                    }}
                                                    onBlur={handleBlur("phone")}
                                                    value={phone??""}
                                                    error={phoneError}
                                                    returnKeyType={"done"} />

                                                {authChangesAllowed && (
                                                    <View style={{ marginTop: 9 }}>
                                                        <Text bold strapline style={{ color: Colours.GREY_60 }}>
                                                            {Strings.password.toUpperCase()}
                                                        </Text>
                                                        <View
                                                            style={styles.changePasswordContainer}
                                                        >
                                                            <Text
                                                                strapline
                                                                style={{
                                                                    marginTop: 10,
                                                                    marginLeft: 18,
                                                                }}
                                                            >
                                                                {"•••••••••••"}
                                                            </Text>
                                                            <Text
                                                                style={styles.changePassword}
                                                                onPress={goChangePassword}
                                                            >
                                                                {Strings.update}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )}
                                            </View>
                                            <View style={{top: 18}}>
                                                <HelperBlock error={error} />
                                            </View>
                                            <View style={styles.footer}>
                                                    {!editing ? (
                                                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Button
                                                                style={{ width: "100%" }}
                                                                plain
                                                                border
                                                                center
                                                                textProps={{ h5: true }}
                                                                loading={loading}
                                                                disabled={loading || (editing && !formValid)}
                                                                onPress={onButtonPress}>
                                                                {Strings.edit_my_details}
                                                            </Button>
                                                            <Text
                                                                red
                                                                center
                                                                h5
                                                                style={{ marginTop: 20, textDecorationLine: "underline" }}
                                                                onPress={() => deleteRef.current?.show()}>
                                                                {Strings.delete_account}
                                                            </Text>
                                                        </View>
                                                    ) : (
                                                        <View style={{ flexDirection: "row" }}>
                                                            <Button
                                                                style={{ flex: 1, margin: 6 }}
                                                                border
                                                                center
                                                                plain
                                                                textProps={{ h5: true }}
                                                                onPress={async () => {
                                                                    setEditing(false);
                                                                    setError(null);
                                                                    await setSelectedCountry(countryCode);
                                                                    setPhone(user?.phone?.replace(initialAreaCode ?? "", "") ?? "");
                                                                    resetForm();
                                                                } }>
                                                                {Strings.cancel}
                                                            </Button>
                                                            <Button
                                                                style={{ flex: 1, margin: 6 }}
                                                                center
                                                                textProps={{ h5: true }}
                                                                loading={loading}
                                                                disabled={loading || (editing && !formValid)}
                                                                onPress={onButtonPress}>
                                                                {Strings.done}
                                                            </Button>
                                                        </View>
                                                    )}
                                                </View>
                                            </>
                                  )
                                    }}
                                    </Formik>

                        <Dialog
                            ref={deleteRef}
                            label={Strings.delete_account}
                            labelProps={{
                                style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" },
                            }}
                            title={Strings.are_you_sure_you_want_to_delete_your_account}
                            titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                            negativeText={Strings.no}
                            negativeProps={{ textProps: { h5: true } }}
                            positiveText={Strings.delete}
                            positiveProps={{ red: true, textProps: { h5: true } }}
                            onPositivePress={onDeleteUserAccountPress}
                        />

                        <Dialog
                            ref={passwordDialogRef}
                            label={Strings.enter_password_title}
                            labelProps={{ style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" } }}
                            title={Strings.enter_password_desc}
                            titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                            positiveText={Strings.ok}
                            negativeText={Strings.cancel}
                            positiveProps={{disabled: !password, loading: isPasswordSubmitting}}
                            negativeProps={{disabled: isPasswordSubmitting}}
                            disableCloseOnPositive={true}
                            onPositivePress={() => formRef.current?.submitForm()}
                            onClose={onPasswordFormClose}
                        >
                            <Input
                                password
                                label={Strings.password}
                                value={password}
                                onChangeText={setPassword}
                            />
                            <Text red>{passwordError}</Text>
                        </Dialog>

                </View>
            </TouchableWithoutFeedback>

            <Dialog
                style={[{top},styles.pwdUpdatedDialog]}
                ref={pwdUpdatedDialogRef}
                icon={Icons.checkboxtick}
                iconProps={{ white: true }}
                label={Strings.password_updated}
                title={Strings.password_updated_success}
            />
        </ParkableBaseView>
    );
};

class MyDetailsParams {
    passwordUpdated?: boolean;
}

export const MyDetailsRoute = createRoute({
    path: Routes.MyDetailsView,
    params: {type: MyDetailsParams}
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    header: {
        top: 9,
    },
    section: {
        flex: 1,
    },
    footer: {
    	marginTop: 27,
        marginBottom: 30
    },
    link: {
        color: Colours.BLUE_300,
        fontWeight:"normal",
        textAlign:"right"
    },
    disabledLink: {
        color: Colours.GREY_50,
        fontWeight:"normal",
        textAlign:"right"
    },
    pwdUpdatedDialog: {
        position:'absolute',
        width:'100%'
    },
    pendingEmailChange: {
        fontSize: 12,
        lineHeight: 16,
        color: "red",
    },
    pendingEmailChangeButton: {
        textDecorationLine: "underline",
    },
    input: {
        marginTop: 9,
    },
    disabled:{
        backgroundColor: Colours.GREY_10
    },
    inputTextArea: {
        height: 100,
        paddingTop: 60,
    },
    inputGroup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 9
    },
    changePasswordContainer: {
            borderWidth: 1,
            borderColor: Colours.GREY_BORDER,
            backgroundColor: Colours.GREY_10,
            height: 45,
            borderRadius: 3,
            position: 'relative',
    },
    changePassword: {
        position: 'absolute',
        right: 20,
        top: 16,
        fontWeight: "400",
        fontSize: 12,
        lineHeight: 12,
        textDecorationLine:"underline",
        color: Colours.GREY_60,
}
});
