import { Bay, Feature } from "react/model/Bay";
import { BayDTO, SharingPoolBayDTO } from "react/model/BayDTO";
import { Colours } from "react/parkable-components";

export const getBayTileColour = (isSelected?: boolean, isSubscription?: boolean, isSharingPool?: boolean) => {
    if (isSubscription) {
        return isSelected ? Colours.ORANGE_200 : Colours.ORANGE_100;
    }
    if (isSharingPool) {
        return isSelected ? Colours.SALMON_300 : Colours.SALMON_100;
    }
    return isSelected ? Colours.BLUE_300 : Colours.BLUE_100;
};

export const getBayFeature = (bays?: Bay[] | BayDTO[]) => {
    return {
        isEV: bays?.some((bay) => bay.features?.some((feature) => feature === Feature.ELECTRIC)),
        isMotorbike: bays?.some((bay) => bay.features?.some((feature) => feature === Feature.MOTORBIKE)),
    };
};

export const mapBayToBayWithSharingPool = (allBays?: BayDTO[] , sharingPoolBays?: BayDTO[]): SharingPoolBayDTO[] => {
    return (allBays || [])?.map(b => ({
        ...b,
        isSharingPool: !!sharingPoolBays?.find(spb => spb.id === b.id),
    }))
}
