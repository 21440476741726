import React from "react";
import Text from "react/parkable-components/text/Text";
import {Image, View} from "react-native";
import {contentStyles} from "./how-it-works.styles";
import Strings from "../../constants/localization/localization";
import {useTerritories} from "../../api/territory/territory.api";
import {useOrganisation} from "../../api/organisation/organisation.api";
import Constants from "../../constants/constants";
import { Routes } from "react/navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {PADDING} from "../../root/root.constants";

const HandImage = require("../../resources/hand.png");

class HowItWorksRewardsParams {
    organisationId: number;
    territoryId?: number;
}

const _HowItWorks_Rewards = (props: NavigationProps<Routes.HowItWorks_Rewards>) => {
    const params = props.route.params;
    const {territories, isLoading:territoryLoading} = useTerritories();
    const {organisation, isLoading} = useOrganisation(params?.organisationId);
    const territory = territories?.find(t => t.id === organisation?.territory);

    return (
        <ParkableBaseView removeStandardMargins toolbarStyle={{marginLeft: PADDING}} loading={isLoading||territoryLoading}>
            <View style={{flex: 1, paddingHorizontal: PADDING}}>
                <View style={contentStyles.heading}>
                    <Text h1>{Strings.rewards.how_it_works}</Text>
                </View>

                {territory && <Text style={contentStyles.mainText}>
                    {Strings.rewards.how_it_works_describe(organisation?.bonusSessionMargin ?? 0, territory?.currencyCode)}
                </Text>}

                <Text style={contentStyles.mainText}>
                    {Strings.rewards.how_it_works_describe_once_you_have(Constants.minimumPointsToRedeemed)}
                </Text>
                <Text style={contentStyles.mainText}>
                    {Strings.rewards.how_it_works_expires_and_redeems}
                </Text>
            </View>
            <View style={contentStyles.graphicContainer}>
                <Image source={HandImage} style={contentStyles.image}/>
            </View>
        </ParkableBaseView>
    );
};

export const HowItWorks_Rewards = _HowItWorks_Rewards;

export const HowItWorksRewardsRoute = createRoute({
    path: Routes.HowItWorks_Rewards,
    params: {type: HowItWorksRewardsParams}
})
