import React from "react";
import { StyleSheet, View } from "react-native";
import Strings from "../../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import Colours from "react/parkable-components/styles/Colours";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import { useNavigation } from "react/navigation/constants";
import { useCurrentParkingSession } from "react/api/parking";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import { getCurrentUser, useCurrentUser } from "react/api/user/user.api";
import { useSelector } from "react/redux/redux";
import { IRootReducer } from "react/redux/reducers/main";
import Constants from "react/constants/constants";
import { usePark } from "react/api/park";
import { EmployeeSubscriptionDTO } from "../../../dto/EmployeeSubscriptionDTO";

type AccountVehiclesCardItemProps = {
    parkId?: number;
    subscription?: EmployeeSubscriptionDTO;
    selectSubscriptionVehicles?: boolean;
};

const AccountVehiclesCardItem = (props: AccountVehiclesCardItemProps) => {
    const navigation = useNavigation();
    const { parkId, subscription, selectSubscriptionVehicles } = props;
    const { park } = usePark(parkId);
    const hasParkVehicleLimitPerSubscription = park?.maxVehiclesPerSubscription !== null;
    const { user, isLoading: userLoading } = useCurrentUser();
    const { vehicles: userVehicles, isLoading: vehiclesLoading, mutate: mutateVehicles } = useUserVehicles();
    const { selectedVehicleIds } = useSelector((state: IRootReducer) => state.vehicles);
    const { parkSession } = useCurrentParkingSession();
    const disableVehicleChange =
        subscription?.status === Constants.Subscription_Status_Deleted ||
        subscription?.status === Constants.Subscription_Status_Ended;
    const loading = userLoading || vehiclesLoading;

    const getDisplayedVehicleIds = () => {
        if (subscription && selectSubscriptionVehicles) {
            return hasParkVehicleLimitPerSubscription ? (subscription?.vehicles || []) : [user?.vehicleId];
        }
        if (selectedVehicleIds?.length && selectSubscriptionVehicles) {
            return selectedVehicleIds;
        }
        if (parkSession?.vehicle) {
            return [parkSession?.vehicle]
        }
        const defaultVehicleId = user?.vehicleId ?? userVehicles?.[0]?.id;
        return defaultVehicleId ? [defaultVehicleId] : [];
    };

    const vehicles = userVehicles?.filter((v) => getDisplayedVehicleIds().includes(v.id));
    const isMotorbike = vehicles && vehicles?.length === 1 ? vehicles[0].feature === "MOTORBIKE" : false;

    const onEditVehiclePress = () => {
        if (userVehicles?.length) {
            navigation.push(Routes.VehiclesView, {
                parkId,
                subscription,
                selectSubscriptionVehicles,
                onBackPress: async () => {
                    await getCurrentUser();
                    await mutateVehicles();
                },
            });
        } else {
            navigation.push(Routes.AddNewVehicleView, {});
        }
    };

    return (
        <TableRow
            label={Strings.vehicle}
            chevron={!disableVehicleChange}
            iconLeft={isMotorbike ? "motorbike" : "car"}
            onPress={disableVehicleChange ? () => {} : onEditVehiclePress}
        >
            {vehicles ? (
                vehicles.map((v) => (
                    <View key={v.id}>
                        <Text small key={v.id} style={styles.rego}>
                            {loading ? Strings.loading : v.registration}
                        </Text>
                    </View>
                ))
            ) : (
                <View>
                    <Text small style={styles.rego}>
                        {Strings.no_vehicles}
                    </Text>
                </View>
            )}
        </TableRow>
    );
};

const styles = StyleSheet.create({
    rego: {
        fontWeight: "500",
        borderWidth: 1,
        borderColor: Colours.GREY_80,
        alignSelf: "flex-start",
        height: 22,
        textAlignVertical: "center",
        paddingTop: 1,
        paddingLeft: 3,
        paddingRight: 3,
        marginRight: 5,
    },
});

export default AccountVehiclesCardItem;
