import React from "react";
import TableRow from "react/parkable-components/tableRow/TableRow";
import { Moment } from "moment";
import { Moment as MomentTZ } from "moment-timezone";
import Strings from "../../../constants/localization/localization";

export type DateRowProps = {
    date: MomentTZ | Moment;
};

export const DateRow = ({ date }: DateRowProps) => {
    return (
        <TableRow label={Strings.date} iconLeft="calendar">
            {date.format("ddd, Do MMM, YYYY")}
        </TableRow>
    );
};
