import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { NextOpenDay } from "../../constants/Util";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Dialog from "react/components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import UserDetailsCard from "../common/UserDetailsCard";
import moment from "moment";
import { logEvent } from "react/analytics";
import { usePark, useParks } from "../../api/park/park.api";
import { useCampus } from "../../api/campus/campus.api";
import { useCurrentUser } from "../../api/user/user.api";
import { FutureParkingInfo, futureParkingInfo } from "../parkDetails/startParkingButtonStatus";
import _ from "lodash";
import { useUserVehicles } from "../../api/vehicle/vehicle.api";
import { createParkTomorrowParkingRequest } from "../../api/parking-request/parking-request.api";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { CarParkNameRow } from "../widgets/table-rows/car-park-row";
import DriveTimeTableRow from "react/components/parkDetails/DriveTimeTableRow";
import { isErrorFailedTransaction } from "react/constants/ExceptionHandler";

const ParkTomorrowView = (props: NavigationProps<Routes.ParkTomorrowView>) => {
    const { navigation } = props;

    const { parkId, campusId, organisationId } = props.route.params;

    const { user } = useCurrentUser();
    const { vehicles } = useUserVehicles();
    const { park } = usePark(parkId);
    const { campus } = useCampus(organisationId, campusId);
    const { parks } = useParks(campus?.parks);

    const errorDialogRef = useRef<DialogRef | null>(null);
    const [loading, setLoading] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [title, setTitle] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogOnConfirmFunction, setDialogOnConfirmFunction] = useState<undefined | (() => void)>();

    const [nextOpenDay, setNextOpenDay] = useState<NextOpenDay | undefined>(undefined);
    const [nextOpenDayString, setNextOpenDayString] = useState<string | undefined>(undefined);

    const localizedDay = (day: string) => {
        switch (day) {
            case "sunday":
                return Strings.sunday;
            case "monday":
                return Strings.monday;
            case "tuesday":
                return Strings.tuesday;
            case "wednesday":
                return Strings.wednesday;
            case "thursday":
                return Strings.thursday;
            case "friday":
                return Strings.friday;
            case "saturday":
                return Strings.saturday;
            default:
                throw "localizedDay: Unknown day";
        }
    };

    useEffect(() => {
        const processParkInfo = ({ availableToday, isParkOpenTomorrow, nextOpenDay }: FutureParkingInfo) => {
            const nextOpenDayString = nextOpenDay?.openDay.format("dddd Do MMMM");
            setNextOpenDay(nextOpenDay);

            setNextOpenDayString(availableToday ? Strings.today : nextOpenDayString);

            const futureParkingTitle = availableToday
                ? Strings.want_to_park_later_today
                : isParkOpenTomorrow
                ? Strings.want_to_park_tomorrow
                : Strings.want_to_park_on_someday({
                      isNextWeek: nextOpenDay?.isNextWeek ?? false,
                      text: localizedDay(nextOpenDay?.dayOfWeek ?? ""),
                  });

            setTitle(futureParkingTitle);
        };

        if (!!park) {
            processParkInfo(futureParkingInfo(park));
        } else if (!!parks?.length) {
            //campus: find the soonest opening park in the campus
            const infos = parks.map(futureParkingInfo);
            const nextOpen =
                infos.find((i) => i.availableToday) ??
                infos.find((i) => i.isParkOpenTomorrow) ??
                _.orderBy(infos, (i) => i.nextOpenDay?.openDay)?.[0];
            if (nextOpen) {
                processParkInfo(nextOpen);
            }
        }
    }, [park, parks]);

    const showRetryPaymentView = (sessionId?: number) => {
        navigation.push(Routes.RetryPaymentRequest, sessionId ? { sessionId } : {});
    };

    const onConfirmRequestPress = async () => {
        const params = {
            userId: `${user?.id}`,
            parkId: `${parkId}`,
            campusId: `${campusId}`,
            organisationId: `${organisationId}`,
        };
        logEvent(undefined, "park_tomorrow_confirm_request", params);

        setLoading(true);
        try {
            const response = await createParkTomorrowParkingRequest(parkId, organisationId, campusId, user?.vehicleId);

            if (!!response?.parkingRequest) {
                await navigation.pop();
                navigation.push(Routes.ConfirmedBookingView, {
                    parkingRequestId: response.parkingRequest.id,
                    backButtonToMapView: true,
                });
                logEvent(undefined, "park_tomorrow_accepted", params);
            } else {
                const params2 = { ...params, reason: `No_Bays_Available` };
                logEvent(undefined, "park_tomorrow_denied", params2);

                setDialogTitle(Strings.check_later_to_see_if_changed);
                setDialogText(Strings.sorry_no_parks_available);
                errorDialogRef.current?.show();
            }
        } catch (_err) {
            const err = _err as any;
            const isPaymentFailedError = isErrorFailedTransaction(err);
            const code = err?.code ?? 0;
            const params3 = {
                ...params,
                reason: `${
                    isPaymentFailedError ? "Pending_Failed_Payment" : code === 278 ? "No_Bays_Available" : "Unknown"
                }`,
            };
            logEvent(undefined, "park_tomorrow_denied", params3);

            if (isPaymentFailedError) {
                //failed transaction
                setDialogOnConfirmFunction(() => showRetryPaymentView(err?.data));
            } else {
                setDialogOnConfirmFunction(undefined);
            }

            if (code === 278) {
                //we want to display an error message based on the day the request
                //was for so we explicitly catch the no bay error
                displayExplicitUserFriendlyErrorString();
            } else {
                setDialogTitle(err?.message ?? Strings.internal_error_if_persists);
                setDialogText(Strings.error);
            }

            errorDialogRef.current?.show();
        }

        setLoading(false);
    };

    const displayExplicitUserFriendlyErrorString = () => {
        let message = Strings.sorry_there_are_no_bays_available;
        const tomorrow = moment().add(1, "days");

        if (nextOpenDay?.openDay.dayOfYear() === tomorrow.dayOfYear()) {
            message += " " + Strings.tomorrow;
        } else {
            if (nextOpenDay?.isNextWeek) {
                message += " " + Strings.lowercase_next + " ";
            } else {
                message += " " + Strings.lowercase_on + " ";
            }
            message += " " + nextOpenDay?.openDay.format("dddd");
        }
        setDialogTitle(message);
        setDialogText(Strings.uh_oh);
    };

    const userHasVehicle = () => {
        const isVehiclesNullOrEmpty = (vehicles?.length ?? 0) === 0;
        return user?.vehicleId && !isVehiclesNullOrEmpty;
    };

    const confirmButtonDisabled = !userHasVehicle();

    const onPositiveDialogPress = () => {
        errorDialogRef.current?.hide();
        if (!!dialogOnConfirmFunction) {
            dialogOnConfirmFunction();
        }
    };

    return (
        <ParkableBaseView loading={loading}>
            <View>
                <Text
                    numberOfLines={2}
                    h1
                    style={[
                        {
                            fontSize: 32,
                            lineHeight: 35,
                            marginBottom: 37,
                        },
                    ]}
                >
                    {title}
                </Text>
                <Text style={{ paddingBottom: 15 }}>{Strings.pfb_park_tomorrow_text}</Text>
                {!!park?.displayName && <CarParkNameRow displayName={park?.displayName} />}

                {park ? (
                    <DriveTimeTableRow park={park} />
                ) : (
                    <TableRow
                        label={Strings.location}
                        textProps={{ numberOfLines: 1 }}
                        iconLeft={"locationpin"}
                    >
                        {campus?.name ?? ""}
                    </TableRow>
                )}

                <TableRow
                    label={Strings.date}
                    textProps={{ numberOfLines: 1 }}
                    iconLeft={"locationpin"}
                >
                    {nextOpenDayString}
                </TableRow>
                <UserDetailsCard />
                <Button
                    style={{ paddingHorizontal: 30, marginTop: 35, justifyContent: "space-between" }}
                    center
                    iconRight={"arrowboldright"}
                    onPress={onConfirmRequestPress}
                    disabled={confirmButtonDisabled}
                >
                    {Strings.confirm_request}
                </Button>
            </View>
            <Dialog
                ref={errorDialogRef}
                label={dialogText}
                title={dialogTitle}
                positiveText={Strings.ok}
                labelProps={{
                    style: {
                        color: Colours.NEUTRALS_BLACK,
                        textAlign: "left",
                        borderBottomWidth: 1,
                        borderBottomColor: Colours.GREY_10,
                    },
                }}
                titleProps={{ h2: undefined, small: true, style: { textAlign: "left", lineHeight: 24 } }}
                positiveProps={{ red: true, textProps: { h5: true } }}
                onPositivePress={onPositiveDialogPress}
            />
        </ParkableBaseView>
    );
};

class ParkTomorrowViewProps {
    parkId?: number | null;
    campusId?: number | null;
    organisationId?: number | null;
}

export default ParkTomorrowView;

export const ParkTomorrowRoute = createRoute({
    path: Routes.ParkTomorrowView,
    params: { type: ParkTomorrowViewProps },
});

const styles = StyleSheet.create({
    outerFooter: {
        position: "absolute",
        top: 0,
        backgroundColor: "transparent",
        width: "100%",
        zIndex: 100,
    },
});
