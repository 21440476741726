import React from "react";
import { Routes } from "../../navigation/root/root.paths";
import { StyleSheet, View } from "react-native";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Strings from "react/constants/localization/localization";
import { useSupportCase } from "react/api/support/support.api";
import moment from "moment";
import { usePark } from "react/api/park";
import Icons from "react/parkable-components/icon/Icons";
import { useCalendars } from "expo-localization";

class CaseDetailsViewParams {
    caseId: string;
}

const CaseDetailsView = (props: NavigationProps<Routes.CaseDetailsView>) => {
    const { caseId } = props.route.params;
    const { supportCase, isLoading } = useSupportCase(caseId);
    const { park } = usePark(supportCase?.parkId ? parseInt(supportCase.parkId) : undefined);

    const messageDateFormat = "DD MMM YYYY";
    const userMobile = useCalendars();
    const userAppTimezone = userMobile[0].timeZone ?? "UTC";

    return (
        <ParkableBaseView>
            <View>
                <Text h1 bold>
                    {Strings.case_details}
                </Text>
            </View>
            {!supportCase || isLoading ? (
                <View>
                    <Spinner large />
                </View>
            ) : (
                <View>
                    <View>
                        <TableRow label={Strings.created_date} iconLeft={Icons.calendar}>
                            {moment.utc(supportCase?.createdDate).tz(userAppTimezone).format(messageDateFormat)}
                        </TableRow>
                        <TableRow
                            label={Strings.case_number_label}
                            iconLeft={Icons.folder}
                        >{`#${supportCase.caseNumber}`}</TableRow>
                        <TableRow label={Strings.subject} iconLeft={Icons.messagebubble}>
                            {supportCase.subject}
                        </TableRow>
                        <TableRow label={Strings.status} iconLeft={Icons.refresh}>
                            {supportCase.status}
                        </TableRow>
                        <TableRow label={Strings.last_activity} iconLeft={Icons.notification}>
                            {moment(supportCase.lastModifiedDate).tz(userAppTimezone).format(messageDateFormat)}
                        </TableRow>
                        {park && (
                            <TableRow
                                label={Strings.location_}
                                iconLeft={Icons.locationpin}
                            >{`${park.displayName}, ${park.address}`}</TableRow>
                        )}
                    </View>
                    {!!supportCase.description.length && (
                        <View style={styles.footer}>
                            <Text h2 bold>
                                {Strings.description}
                            </Text>
                            <Text>{supportCase.description}</Text>
                        </View>
                    )}
                </View>
            )}
        </ParkableBaseView>

    );
};

export default CaseDetailsView;

export const CaseDetailsRoute = createRoute({
    path: Routes.CaseDetailsView,
    params: {
        type: CaseDetailsViewParams,
    },
});

const styles = StyleSheet.create({
    footer: {
        marginTop: 27,
    }
});
