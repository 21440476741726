import React, { useRef } from "react";
import { useNavigation } from "react/navigation/constants";
import { DialogRef, Spinner, TableRow } from "react/parkable-components";
import { usePark } from "react/api/park";
import { useOrganisation } from "react/api/organisations";
import { useBayGroup } from "react/api/bayGroup/bayGroup.api";
import { useTandemPod } from "react/api/tandem/tandem.api";
import { useBays } from "react/api/bay/bay.api";
import Strings from "react/constants/localization/localization";
import { Bay } from "react/model/Bay";
import { ParkingType } from "react/model/Types";
import { BayDTO, SharingPoolBayDTO } from "react/model/BayDTO";
import { getBaySignage } from "react/constants/baySignage";
import BayComponent from "./BayComponent";
import { Routes } from "react/navigation/root/root.paths";
import { getBayFeature, getBayTileColour } from "./util";
import Dialog from "react/components/dialog/Dialog";
import { useUserRoles } from "react/api/user/user.api";
import { userIsOrgMember } from "react/constants/getUserMember";

type Props = {
    bay?: Bay | BayDTO;
    parkId: number;
    onChange?: (bay: Bay) => void;
    baysAvailable: SharingPoolBayDTO[];
    parkingType?: ParkingType;
    loading?: boolean;
    disableBayAvailabilityWarning?: boolean;
};

export const BayRow = (props: Props) => {
    const {
        bay,
        onChange,
        parkId,
        parkingType,
        baysAvailable,
        loading,
        disableBayAvailabilityWarning,
    } = props;
    const navigation = useNavigation();
    const showWhenOneBayAvailable = useRef<DialogRef | null>(null);

    const { userRoles } = useUserRoles();
    const { park } = usePark(parkId);
    const { tandemPod, isLoading: loadingTandemPod } = useTandemPod(bay?.tandemPod);
    const { bays: tandemBays } = useBays(parkId, tandemPod?.bays);

    // Need org and baygroup only if bay is unsigned. Let's not request for them if the bay is signed.
    const { organisation } = useOrganisation(bay && !bay.signage ? park?.organisation : null);
    const { bayGroup } = useBayGroup(organisation?.id, bay?.group);

    const isSharingPool =
        baysAvailable.find((b) => b.id === bay?.id)?.isSharingPool && userIsOrgMember(userRoles, park?.organisation);

    // Loading state.
    if (!bay || loading || loadingTandemPod) {
        return (
            <TableRow iconLeft="bay">
                <Spinner small />
            </TableRow>
        );
    }

    const { rowLabel, label, displayAsPlainText } = getBaySignage(bay, bayGroup, tandemPod);
    const backgroundColor = getBayTileColour(true, parkingType === ParkingType.LONG_TERM, isSharingPool);
    const { isEV, isMotorbike } = getBayFeature(tandemBays || [bay]);

    // Static row. No change bay requirement.
    if (!onChange) {
        return (
            <TableRow iconLeft={"bay"} label={rowLabel} renderChildrenAsObject>
                <BayComponent
                    signage={label}
                    backgroundStyles={{ backgroundColor }}
                    displayEvIcon={isEV}
                    displayMotorbikeIcon={isMotorbike}
                    displayAsPlainText={displayAsPlainText}
                />
            </TableRow>
        );
    }

    const signageBaysAvailable = baysAvailable.filter((b) => b.signage);
    const signageBaysAvailableCount = signageBaysAvailable.length;
    // Exclude the selected bay. Also exclude it's own tandem pod.
    const noOtherBaysAvailable = baysAvailable.filter((b) => b.id !== bay?.id).length === 0 ||
        (bay.tandemPod && baysAvailable.filter((b) => b.tandemPod !== bay.tandemPod).length === 0);

    const isChangingBayAnOption = !!signageBaysAvailableCount && !noOtherBaysAvailable;

    const handleChangePress = () => {
        const params = {
            baysAvailable: signageBaysAvailable,
            onBaySelected: (bay: Bay) => onChange(bay),
            bay: bay,
            parkId: parkId,
            parkingType: parkingType ?? ParkingType.CASUAL,
        };
        navigation.push(Routes.SelectBayView, params);
    };

    const onChangeWarning = () => {
        if (disableBayAvailabilityWarning) {
            return;
        }
        showWhenOneBayAvailable.current?.show();
    };

    return (
        <>
            <TableRow
                iconLeft={"bay"}
                label={rowLabel}
                chevron={isChangingBayAnOption}
                onPress={isChangingBayAnOption ? handleChangePress : onChangeWarning}
                renderChildrenAsObject
            >
                <BayComponent
                    signage={label}
                    backgroundStyles={{ backgroundColor }}
                    displayEvIcon={isEV}
                    displayMotorbikeIcon={isMotorbike}
                    displayAsPlainText={displayAsPlainText}
                />
            </TableRow>
            <Dialog
                ref={showWhenOneBayAvailable}
                title={Strings.sorry_no_other_available_bays}
                positiveText={Strings.ok}
            />
        </>
    );
};
