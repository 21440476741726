import React from "react";
import Text from "react/parkable-components/text/Text";
import { Routes } from "react/navigation/root/root.paths";
import moment from "moment";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import { spacing } from "react/constants/theme/spacing";
import { DayTile } from "react/components/future-booking/day-tile/day-tile";
import { computeFutureBookingStatus, getDayTileProps } from "react/components/future-booking/constants";
import { FutureBookingStatus } from "react/components/future-booking/day-tile/types";
import { Icon } from "react/parkable-components";
import { ActiveParkingRequest } from "react/constants/parking-request.constants";
import { useNavigation } from "react/navigation/constants";
import { usePark } from "react/api/park/park.api";
import { useCampus } from "react/api/campus/campus.api";
import { useOrganisation } from "react/api/organisation/organisation.api";
import Strings from "react/constants/localization/localization";
import { ParkingRequestStatus } from "react/model/ParkingRequest";

type Props = {
    request: ActiveParkingRequest,
};

export default function ParkingRequestCard(props: Props) {
    const { request } = props

    const navigation = useNavigation();
    const { park } = usePark(request.park);
    const { campus } = useCampus(request.campusOrganisation ?? request.organisation, request.campus);
    const { organisation } = useOrganisation(request.organisation);

    const tileProps = getDayTileProps(moment(request.date), moment(), !!request?.isSharingPool);

    const requestStatus = computeFutureBookingStatus(request, null)

    const statusDetail = () => {
        switch (requestStatus) {
            case FutureBookingStatus.Confirmed:
                return request.isSharingPool
                    ? Strings.future_booking.status_detail.confirmed_sharing_pool
                    : Strings.future_booking.status_detail.confirmed;
            case FutureBookingStatus.Requested:
            case FutureBookingStatus.PriorityRequested:
                return Strings.future_booking.status_detail.requested;
            case FutureBookingStatus.WaitListed:
            case FutureBookingStatus.WaitListedNearTop:
                return Strings.future_booking.status_detail.wait_list;
            default:
                return "";
        }
    }

    return (
        <View style={[styles.mainView, styles.shadowProp]}>
            <TouchableWithoutFeedback
                onPress={() => {
                    if (request.status == ParkingRequestStatus.Confirmed) {
                        navigation.push(Routes.ConfirmedBookingView, {
                            parkingRequestId: request.id,
                            backButtonToMapView: false
                        });
                    } else {
                        navigation.push(Routes.FutureBookingView, {
                            parkId: request.park ?? campus?.parks[0] ?? undefined,
                            campusId: request.campus ?? undefined,
                            organisationId: request.campusOrganisation ?? undefined,
                        });
                    }
                }}
            >
                <View style={styles.flexContainer}>
                    <View style={styles.dayContainter}>
                        <DayTile
                            loading={false}
                            status={requestStatus}
                            {...tileProps}
                        />
                    </View>
                    <View style={styles.cardContentContainer}>
                        <Text h4 bold style={styles.carParkName} numberOfLines={1} ellipsizeMode="tail">
                            {park?.displayName ?? park?.address ?? campus?.name ?? ''}
                        </Text>
                        <Text style={styles.status}>{statusDetail()}</Text>
                    </View>
                    <Icon name={"cheveronright"} small color={Colours.NEUTRALS_900} style={styles.chevronRight} />
                </View>
            </TouchableWithoutFeedback>
        </View>
    );
};

const styles = StyleSheet.create({
    mainView: {
        marginVertical: spacing(0.5),
        paddingHorizontal: spacing(2),
        paddingVertical: spacing(2),
        borderRadius: 9,
        borderColor: Colours.GREY_LIGHT,
        borderWidth: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    shadowProp: {
        shadowColor: Colours.SHADOW,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        // Android shadow requires this.
        elevation: 3
    },
    flexContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: spacing(2)
    },
    dayContainter: {
        minWidth: spacing(8)
    },
    cardContentContainer: {
        flex: 1
    },
    carParkName: {
        marginBottom: spacing(0.333),
        lineHeight: 20,
        color: Colours.NEUTRALS_900
    },
    status: {
        color: Colours.GREY_60,
        fontSize: 15,
        lineHeight: 16
    },
    chevronRight: {
        width: 15,
        height: 15,
        marginLeft: "auto"
    }
});
