import React, { useCallback } from "react";
import { View } from "react-native";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { ActivityType, Voucher, VoucherType } from "../../../model/Voucher";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { Territory } from "../../../model/Territory";
import { Park } from "../../../model/Park";
import { useNavigation } from "../../../navigation/constants";

const SelectedVoucherCard = (props: Props) => {
    const { voucher, territory, park, activity, discount } = props;
    const currencyCode = territory?.currencyCode;
    const navigation = useNavigation();

    const onShowVoucherPress = useCallback(() => {
        if (!voucher) {
            navigation.navigate(Routes.AddVoucherCode, {
                park,
                activity,
            });
        } else {
            navigation.navigate(Routes.VoucherDetail, {
                voucher,
                parkId: park?.id,
                activity,
            });
        }
    }, [voucher, navigation]);

    let topLabel = null;
    let label = null;

    switch (voucher?.type) {
        case VoucherType.FREE_SESSION:
            topLabel = Strings.voucher_applied_label;
            label = Strings.free_casual_parking_session(voucher.originalSessionCount!);
            break;

        case VoucherType.FREE_CREDIT:
            topLabel = Strings.voucher_credit;
            label = localizeCurrency(voucher.remainingCreditAmount!, currencyCode);
            break;

        case VoucherType.PERCENT_DISCOUNT:
            topLabel = Strings.voucher_discount;
            if (activity === ActivityType.Casual) {
                label = Strings.percent_off_parking_session(voucher.percentDiscount!);
            } else {
                if (discount) {
                    label = localizeCurrency(discount!, currencyCode);
                } else {
                    label = Strings.percent_off(voucher.percentDiscount!);
                }
            }
            break;
        default:
            topLabel = "";
            label = Strings.enter_voucher_code;
            break;
    }

    return (
        <View>
            <TableRow
                iconLeft={"voucher"}
                chevron
                onPress={onShowVoucherPress}
                label={topLabel}
            >
                {label}
            </TableRow>
        </View>
    );
};

type Props = {
    voucher: Voucher | null;
    territory: Pick<Territory, "currencyCode"> | undefined;
    park: Pick<Park, "id" | "address">;
    activity: ActivityType;
    discount?: number | null;
};

export default SelectedVoucherCard;
