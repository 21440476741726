import { createRoute } from "../constants";
import { Routes } from "./root.paths";
import { LandingRoute } from "../../components/landing/LandingView";
import { LoginOrSignupRoute } from "../../components/landing/LoginOrSignupView";
import { ChangeServerRoute } from "../../components/landing/ChangeServerView";
import { MapRoute } from "../../components/map/parkableMapView/ParkableMapView_root";
import { ParkDetailRoute } from "../../components/parkDetails/ParkDetailView_new";
import { FindParkRoute } from "../../components/search/FindParkView";
import { SupportRoute } from "../../components/support/SupportView";
import { AddNewCaseViewRoute } from "../../components/support/AddNewCaseView";
import { CaseDetailsRoute } from "../../components/support/CaseDetailsView";
import { ConfirmStartParkingRoute } from "../../components/parkDetails/confirmStartParking/ConfirmStartParkingView";
import { ActiveSessionRoute } from "../../components/parkingViews/ActiveSessionView_new";
import { AccountRoute } from "../../components/accountView/AccountView";
import { PreferredBaysRoute } from "../../components/preferredBays/PreferredBaysView";
import { SelectParkRoute } from "../../components/preferredBays/SelectParkView";
import { PricingViewRoute } from "../../components/pricing/PricingView";
import { ForgotPasswordRoute } from "../../components/landing/ForgotPasswordView";
import { MapSettingsRoute } from "../../components/accountView/userDetails/MapSettingsView";
import { MyDetailsRoute } from "../../components/accountView/userDetails/MyDetailsView";
import { SessionHistoryRoute } from "../../components/parkingViews/parking-history/SessionHistoryView";
import { UserBookingsAndRequestsRoute } from "../../components/parkingViews/bookings-and-requests/bookings-and-requests.view";
import { SubscriptionListRoute } from "../../components/parkingViews/Subscriptions/ListSubscriptionView";
import { AddNewVehicleRoute } from "../../components/accountView/userDetails/AddNewVehicleView_new";
import { VehiclesRoute } from "../../components/accountView/userDetails/VehiclesView_new";
import { SamlLoginRoute } from "../../components/landing/SamlLoginView";
import { MessagesRoute } from "../../components/messages/MessagesView";
import { VouchersRoute } from "../../components/vouchers/VouchersView";
import { SessionSummaryRoute } from "../../components/parkDetails/sessionSummary/SessionSummary";
import { NotificationSettingsRoute } from "../../components/accountView/userDetails/NotificationSettingsView";
import { RfidCardsRoute } from "../../components/accountView/userDetails/rfid-cards-view/RfidCardsView";
import { CreditCardRoute } from "../../components/accountView/userDetails/CreditCards";
import { CreateAccountRoute } from "../../components/landing/CreateAccountView";
import { AddNewCardRoute } from "../../components/accountView/userDetails/AddNewCard";
import { SelectBayRoute } from "../../components/common/SelectBayView";
import { ParkAvailabilityViewRoute } from "../../components/parkDetails/ParkAvailabilityView";
import { VoucherDetailRoute } from "../../components/common/voucher/VoucherDetail";
import { AddVoucherCodeRoute } from "../../components/common/voucher/AddVoucherCode";
import { PreferredBaysSelectionViewRoute } from "../../components/preferredBays/PreferredBaysSelectionView";
import { SessionHistoryDetailRoute } from "../../components/parkingViews/parking-history/SessionHistoryDetailView";
import { SelectSubscriptionPlanRoute } from "../../components/subscriptions/SelectSubscriptionPlanView";
import { ParkTomorrowRoute } from "../../components/parkRequest/ParkTomorrowView";
import { ConfirmStartReservationRoute } from "../../components/parkDetails/confirmStartReservation/ConfirmStartReservationView";
import { StartSubscriptionSummaryViewRoute } from "../../components/subscriptions/StartSubscriptionSummaryView";
import { AcceptTsandCsRoute } from "../../components/landing/AcceptTsAndCsView";
import { PrivacySettingsRoute } from "../../components/accountView/userDetails/PrivacySettingsView";
import { QrScannerRoute } from "../../components/common/qrscanner/QrScannerView";
import { ChangePasswordViewRoute } from "../../components/accountView/userDetails/ChangePasswordView";
import { SubscriptionCancelRoute } from "../../components/parkingViews/Subscriptions/SubscriptionCancelView";
import { FutureBookingRoute } from "../../components/future-booking/future-booking.view";
import { ProblemRoute } from "../../components/problem/ProblemView";
import { HowItWorks_ThisWeekRoute } from "../../components/future-booking/how-it-works/this-week.view";
import { HowItWorks_UpcomingWeeksRoute } from "../../components/future-booking/how-it-works/upcoming-weeks.view";
import { SubscriptionDetailsRoute } from "../../components/subscriptions/SubscriptionDetailsView";
import { VerifyEmailRoute } from "../../components/landing/VerifyEmailView";
import { SingleSubscriptionRoute } from "../../components/parkingViews/Subscriptions/SingleSubscriptionView";
import { AssignSensorQrCodeRoute } from "../../components/admin/AssignSensorQrCodeView";
import { ChatDetailsRoute } from "../../components/tandemChat/ChatDetailsView";
import { ScanSensorQrCodeRoute } from "../../components/admin/ScanSensorQrCodeView";
import { StartChargingByLinkRoute } from "../../components/parkDetails/StartChargingByLinkView";
import { ConfirmedBookingRoute } from "../../components/future-booking/confirmedBooking/confirmedBookingView";
import { SubscriptionInvoicesRoute } from "../../components/subscriptions/subscriptionListItems/SubscriptionInvoicesView";
import { SubscriptionSharingCreditRoute } from "../../components/subscriptions/SubscriptionSharingCreditView";
import { CampusScreenRoute } from "../../components/parkDetails/CampusScreenView";
import { ConnectEVNozzleRoute } from "../../components/ev/ConnectEVNozzle";
import { RetryPaymentRoute } from "../../components/parkingViews/RetryPayment";
import { TandemChatRoute } from "../../components/tandemChat/TandemChatView";
import { ConfirmStartTandemParkingRoute } from "../../components/tandemParking/ConfirmStartTandemParkingView";
import { MagicLinkRoute } from "../../components/landing/MagicLinkView";
import { MyRewardsViewRoute } from "../../components/rewards/myRewardsView";
import { HowItWorksRewardsRoute } from "../../components/rewards/how-it-works.view";
import { AssignSensorBayListRoute } from "../../components/admin/AssignSensorBayList";
import { AssignQRForBayRoute } from "../../components/admin/AssignQRForBay";
import { AdminLoginAsUserRoute } from "../../components/admin/AdminLoginAsUserView";
import { WarningOpenSessionRoute } from "../../components/parkingViews/WarningOpenSessionView";
import { AutoEndSessionRoute } from "../../components/parkingViews/AutoEndSessionView";
import { UpdateCheckRoute } from "../../components/landing/UpdateCheckView";
import { TeamsLandingPageRoute } from "../../components/teams/TeamsLandingPage";
import { TeamsAuthStartRoute } from "../../components/teams/TeamsAuthStart";
import { TeamsAuthEndRoute } from "../../components/teams/TeamsAuthEnd";
import { SharingPoolViewRoute } from "react/components/parkDetails/sharing-pool/SharingPoolView";
import { EditVehicleRoute } from "../../components/accountView/userDetails/EditVehicleView";

declare global {
    namespace ReactNavigation {
        interface RootParamList extends RootStackParamList {}
    }
}

export type RoutesMap = typeof routesMap;
type ParamType<T extends ReturnType<typeof createRoute>> = T["params"]["__type"];

export type RootStackParamList = {
    [Key in keyof RoutesMap as RoutesMap[Key]["path"] & Routes]: ParamType<RoutesMap[Key]>;
};

/** Routes that require no special treatment. For more precise routes, manually add types to
 * RootStackParamList and the Navigation component
 */
const routesMap = {
    MapRoute,
    LandingRoute,
    LoginOrSignupRoute,
    VerifyEmailRoute,
    MagicLinkRoute,
    ChangeServerRoute,
    UpdateCheckRoute,
    ParkDetailRoute,
    CampusScreenRoute,
    ConnectEVNozzleRoute,
    CreateAccountRoute,
    FindParkRoute,
    SupportRoute,
    AddNewCaseViewRoute,
    CaseDetailsRoute,
    SingleSubscriptionRoute,
    MyDetailsRoute,
    SubscriptionCancelRoute,
    ConfirmStartParkingRoute,
    StartSubscriptionSummaryViewRoute,
    PreferredBaysRoute,
    SelectParkRoute,
    PricingViewRoute,
    ActiveSessionRoute,
    ChangePasswordViewRoute,
    RetryPaymentRoute,
    AccountRoute,
    MapSettingsRoute,
    SessionHistoryRoute,
    ProblemRoute,
    UserBookingsAndRequestsRoute,
    SubscriptionListRoute,
    SubscriptionInvoicesRoute,
    SubscriptionSharingCreditRoute,
    AddNewVehicleRoute,
    EditVehicleRoute,
    PreferredBaysSelectionViewRoute,
    ForgotPasswordRoute,
    VehiclesRoute,
    ConfirmedBookingRoute,
    AcceptTsandCsRoute,
    SamlLoginRoute,
    MessagesRoute,
    SelectSubscriptionPlanRoute,
    SubscriptionDetailsRoute,
    ParkTomorrowRoute,
    ConfirmStartReservationRoute,
    ConfirmStartTandemParkingRoute,
    VouchersRoute,
    SessionSummaryRoute,
    NotificationSettingsRoute,
    FutureBookingRoute,
    HowItWorks_ThisWeekRoute,
    HowItWorks_UpcomingWeeksRoute,
    RfidCardsRoute,
    AssignQRForBayRoute,
    AssignSensorBayListRoute,
    AssignSensorQrCodeRoute,
    ScanSensorQrCodeRoute,
    ChatDetailsRoute,
    TandemChatRoute,
    CreditCardRoute,
    AddNewCardRoute,
    SelectBayRoute,
    ParkAvailabilityViewRoute,
    SharingPoolViewRoute,
    VoucherDetailRoute,
    QrScannerRoute,
    AddVoucherCodeRoute,
    StartChargingByLinkRoute,
    WarningOpenSessionRoute,
    AutoEndSessionRoute,
    PrivacySettingsRoute,
    SessionHistoryDetailRoute,
    MyRewardsViewRoute,
    HowItWorksRewardsRoute,
    AdminLoginAsUserRoute,
    TeamsLandingPageRoute,
    TeamsAuthStartRoute,
    TeamsAuthEndRoute,
};

export const routesList: RoutesMap[keyof RoutesMap][] = Object.keys(routesMap).map((path) => {
    const route = routesMap[path as keyof RoutesMap];
    return route;
});
