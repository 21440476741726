import { Bay } from "react/model/Bay";
import { BayGroup } from "react/model/BayGroup";
import { TandemPodDTO } from "react/model/TandemPod/TandemPodDTO";
import Strings from "./localization/localization";
import {BayDTO} from "react/model/BayDTO";

export const getBaySignage = (bay: Bay | BayDTO, bayGroup?: BayGroup | null, tandemPod?: TandemPodDTO) => {
    const unsignedOrTandemRowLabel = bay.tandemPod
            ? Strings.park_furthest_forward_in
            : Strings.park_anywhere_in;
    const rowLabel = !bay.signage
        ? unsignedOrTandemRowLabel
        : Strings.bay;

    const label = bay.tandemPod && tandemPod
        ? tandemPod.name
        : bay.signage || (bayGroup?.name ?? Strings.any_bay);

    return {
        rowLabel,
        label,
        displayAsPlainText: !bay.signage
    };
};

export const getBayNumberHeaderSignage = (bay?: Bay | null, bayGroup?: BayGroup, tandemPod?: TandemPodDTO) => {
    const isUnsignedBayOrTandemBay = !bay?.signage || !!bay?.tandemPod;

    const unsignedOrTandemRowLabel =
        isUnsignedBayOrTandemBay && bay?.tandemPod
            ? Strings.park_furthest_forward_in
            : Strings.park_anywhere_in;
    const rowLabel = isUnsignedBayOrTandemBay
        ? unsignedOrTandemRowLabel
        : Strings.your_bay_number;

    const label = bay?.tandemPod
        ? tandemPod?.name
        : bay?.signage || (bayGroup?.name ?? Strings.any_bay);

    return {
        rowLabel,
        label,
    };
};
