import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import { Button, Select, TableRow, Text } from "react/parkable-components";
import Strings from "../../../constants/localization/localization";
import { ParkDTOWithTerritory } from "react/redux/actions/parks";
import AccountCreditCardsCardItem from "../../common/cardItems/AccountCreditCardsCardItem";
import { connect } from "react-redux";
import { IRootReducer } from "react/redux/reducers/main";
import { Routes } from "react/navigation/root/root.paths";
import { Bay } from "react/model/Bay";
import { Term } from "react/model/Term";
import SubscriptionPriceTableRow from "./SubscriptionPriceTableRow";
import moment from "moment";
import { getSubscriptionsEndAtTermEnd } from "react/constants/Util";
import { OrganisationDTO } from "react/model/OrganisationDTO";
import Constants from "../../../constants/constants";
import { cancelEmployeeSubscription, getEmployeeSubscriptionsByUser } from "react/redux/actions/subscriptions";
import SubscriptionAdditionalDaysDueNowRow from "./SubscriptionAdditionalDaysDueNowRow";
import { getNextPaymentDate } from "./getNextPaymentDate";
import { useLastTermAmount } from "react/api/subscription";
import { showAlert, showConfirmation } from "react/alerts";
import { StripeSubscriptionInvoice } from "react/model/StripeSubscriptionInvoice";
import { StripeSubscriptionRefund } from "react/model/StripeSubscriptionRefund";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";
import { CarParkNameRow } from "react/components/widgets/table-rows/car-park-row";
import { useCurrentUser } from "react/api/user/user.api";
import { EmployeeSubscriptionDTO } from "react/dto/EmployeeSubscriptionDTO";

class SubscriptionCancelViewParams {
    park: ParkDTOWithTerritory;
    subscription: EmployeeSubscriptionDTO;
    bay: Bay;
    organisation: OrganisationDTO | undefined | null;
    isPublicParker: boolean;
}
type Props = NavigationProps<Routes.SubscriptionCancelView>;
const SubscriptionCancelView = (props: Props & ReturnType<typeof reduxProps> & typeof actions) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { cancelEmployeeSubscription, getEmployeeSubscriptionsByUser } = props;
    const [leaveReason, setLeaveReason] = useState<string | null>();
    const [leaveReasonError, setLeaveReasonError] = useState<string | null>();
    const navigation = useNavigation();
    const { user } = useCurrentUser();
    const { park, subscription, bay, organisation, isPublicParker } = props.route.params;

    const { amount: lastTermAmount, isLoading: loadingLastTermAmount } = useLastTermAmount(subscription.id);

    const onProblemPress = useCallback(() => {
        if (!!subscription && user) {
            navigation.navigate(Routes.AddNewCaseView, { subscriptionId: subscription.id });
        }
    }, [user, subscription]);

    const reasons: Item[] = Object.values(CancelReason).map((v) => ({ label: v, value: v }));

    const subEndAtTermEnd = useMemo(() => {
        let result: boolean | undefined | null = true;
        const { status } = subscription;
        if (!!organisation) {
            result =
                (isPublicParker || getSubscriptionsEndAtTermEnd(organisation, park)) &&
                status !== Constants.Subscription_Status_Pending;
        }
        return result;
    }, [subscription, organisation, park, isPublicParker]);

    const nextBillDate = useMemo(() => {
        const { termStartedAt, term, anchorDay, weekAnchorDay } = subscription;
        const next = getNextPaymentDate(term, anchorDay, weekAnchorDay, termStartedAt);
        return next.format("Do MMMM YYYY");
    }, [subscription]);

    const endingAt = useMemo(() => {
        if (!subEndAtTermEnd) {
            return moment().format("Do MMMM YYYY");
        }
        const isWeeklyTerm = subscription.term === Term.Week;
        const endingAt = isWeeklyTerm ? moment().add(1, "week") : moment().add(1, "month");
        return endingAt.format("Do MMMM YYYY");
    }, [subscription]);

    const daysUsed = useMemo(() => {
        if (subscription.status === "Invited" || !subscription.termStartedAt) {
            return 0;
        }
        const termStartedAt = moment(subscription.termStartedAt!);
        return moment().diff(termStartedAt, "days") + 1;
    }, [subscription]);

    const getCancelledDescription = (
        subscriptionHasEnded: boolean,
        invoice?: StripeSubscriptionInvoice,
        refund?: StripeSubscriptionRefund
    ) => {
        if (invoice && invoice?.amountDue > 0) {
            if (invoice.paid) {
                return Strings.subscription_cancelled_after_one_term_payment_done(
                    localizeCurrency(invoice.amountDue, park?.territory?.currencyCode, false),
                    endingAt
                );
            } else {
                return Strings.we_were_unable_to_process_your_payment_please_check_your_payment_details_and_try_again;
            }
        } else if (refund) {
            return Strings.subscription_cancelled_immediately_amount_refunded(
                localizeCurrency(refund.amount, park?.territory?.currencyCode, false)
            );
        }

        if (subscriptionHasEnded) {
            return Strings.subscription_cancelled_after_one_term_no_payment(endingAt);
        } else {
            return Strings.subscription_has_ended;
        }
    };

    const cancelSubscription = useCallback(() => {
        if (!leaveReason) {
            setLeaveReasonError(Strings.required);
            return;
        }

        if (!subscription) {
            return;
        }
        setLoading(true);
        if (!!organisation) {
            const currentStatus = subscription?.status;
            (async (subscription, currentStatus) => {
                try {
                    const result = await cancelEmployeeSubscription(subscription.id, !!subEndAtTermEnd, leaveReason);
                    setLoading(false);
                    if (currentStatus === Constants.Subscription_Status_Invited) {
                        showConfirmation(Strings.invitation_cancelled);
                        navigation.pop();
                    } else if (
                        [
                            Constants.Subscription_Status_Active,
                            Constants.Subscription_Status_HostEnding,
                            Constants.Subscription_Status_LeaserEnding,
                            Constants.Subscription_Status_Pending,
                        ].includes(currentStatus)
                    ) {
                        showConfirmation(
                            getCancelledDescription(
                                result?.employeeSubscription?.status === Constants.Subscription_Status_Deleted,
                                result?.invoice,
                                result?.refund
                            ),
                            Strings.subscription_cancelled
                        );
                        navigation.pop();
                    }
                } catch (err) {
                    setLoading(false);
                    showAlert((err as any)?.message ?? Strings.internal_error_if_persists, Strings.error);
                    navigation.pop();
                } finally {
                    getEmployeeSubscriptionsByUser();
                }
            })(subscription, currentStatus);
        }
    }, [subscription, organisation, leaveReason, setLeaveReason, cancelEmployeeSubscription, navigation]);

    const pickerStyle = {
        inputIOS: styles.pickerText,
        inputAndroid: styles.pickerText,
        inputWeb: styles.pickerText,
        viewContainer: styles.pickerViewContainer,
    };

    return (
        <ParkableBaseView loading={loading}>
            <Text h1>{Strings.cancel_subscription}</Text>
            <View style={styles.subContainer}>
                <View style={styles.main}>
                    <View>
                        <CarParkNameRow displayName={park?.displayName} />
                        <TableRow iconLeft={"locationpin"} label={Strings.location}>
                            <Text small numberOfLines={2}>
                                {park?.address}
                            </Text>
                        </TableRow>
                        <TableRow iconLeft={"bay"} label={Strings.bay_number}>
                            {bay?.signage ?? Strings.any_bay}
                        </TableRow>
                    </View>
                    <View style={styles.section}>
                        <Text h4 bold>
                            {Strings.subscription_details}
                        </Text>
                        <SubscriptionPriceTableRow
                            price={subscription?.pricePerTerm}
                            park={park}
                            currencyCode={park?.territory.currencyCode}
                            isWeeklyTerm={subscription?.term === Term.Week}
                            processingFee={subscription?.processingFee}
                            remarks={`(${Strings.paid_until(nextBillDate)})`}
                        />
                        <TableRow iconLeft={"calendar"} label={Strings.subscription_will_end_on}>
                            {endingAt}
                        </TableRow>
                        {subEndAtTermEnd && (lastTermAmount ?? 0) > 0 && (
                            <>
                                <SubscriptionAdditionalDaysDueNowRow
                                    subscription={subscription}
                                    currencyCode={park?.territory.currencyCode}
                                    lastTermAmount={lastTermAmount!}
                                    loadingLastTermAmount={loadingLastTermAmount}
                                    daysUsed={daysUsed}
                                />
                                <AccountCreditCardsCardItem />
                            </>
                        )}
                    </View>
                    <View style={styles.section}>
                        <Text h4 bold>
                            {Strings.before_you_cancel}
                        </Text>
                        <Select
                            error={leaveReasonError}
                            items={reasons}
                            placeholder={Strings.why_are_you_leaving}
                            onValueChange={(value) => {
                                setLeaveReason(value);
                                setLeaveReasonError(null);
                            }}
                            pickerStyle={pickerStyle}
                            style={{ height: 45 }}
                        />
                    </View>
                    <View style={styles.buttons}>
                        <Button
                            iconLeft={"support"}
                            iconLeftProps={{ color: Colours.NEUTRALS_BLACK }}
                            plain
                            center
                            border
                            textProps={{ h4: true }}
                            onPress={onProblemPress}
                        >
                            {Strings.want_to_message_parkable}
                        </Button>
                        <Button
                            style={styles.button}
                            plain
                            small
                            center
                            textProps={{ red: true, bold: false }}
                            onPress={cancelSubscription}
                        >
                            {Strings.confirm_cancellation}
                        </Button>
                    </View>
                </View>
            </View>
        </ParkableBaseView>
    );
};

const actions = {
    cancelEmployeeSubscription,
    getEmployeeSubscriptionsByUser,
};

export const SubscriptionCancelRoute = createRoute({
    path: Routes.SubscriptionCancelView,
    params: { type: SubscriptionCancelViewParams },
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    subContainer: {
        flex: 1,
    },
    toolbarGradient: {
        width: "100%",
        height: 27,
    },
    outerHeader: {
        width: "100%",
        zIndex: 100,
    },
    main: {
        paddingTop: 18,
        paddingBottom: 18,
    },
    section: {
        paddingTop: 18,
    },
    buttons: {
        paddingVertical: 18,
    },
    button: {
        marginTop: 18,
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
});
const reduxProps = (state: IRootReducer) => {
    return {
        user: state.user.user,
        territoryMap: state.territories.territories,
    };
};
interface Item {
    label: string;
    value: any;
    color?: string;
}
export const CancelReason = {
    LocationChange: `I’m moving location or taking leave`,
    AnotherPark: `I’ve been given a park by my employer`,
    HighPrice: `It’s too expensive`,
    ThereIsAnIssueWithPark: `There is an issue with my park`,
    COVIDRestrictions: `COVID restrictions`,
    Others: `Another reason`,
};
export default connect(reduxProps, actions)(SubscriptionCancelView) as FunctionComponent<Props>;
